import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import { CubitTextFieldAdapter } from '../cubit-inputs/react-form-adapters/cubit-text-field-adapter';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { CubitSelectAdapter } from '../report-select-adapter';
import { useTranslation } from 'react-i18next';
import { ValidatorRequired } from '../../utils/validators/form-validators';
import { ReportBaseQuery } from '../../models/report-details';
import ReportConfigClient from '../../clients/ReportConfigClient';
import { ReportsActions } from '../../redux/reports-actions';
import { useDispatch, useSelector } from 'react-redux';
import pascalcase from '../../utils/pascal-case';
import { SaveReport } from '../../models/save-report';
import { AppState } from '../../redux/app-store';
type Props = {
  reportGroups?: any;
  group?: any;
  setSnackbarOpen?: any;
  setBusyGroupSub?: any;
};
export const AddReport: React.FC<Props> = ({
  reportGroups,
  group,
  setSnackbarOpen,
  setBusyGroupSub,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [baseQueries, setBaseQueries] = useState<ReportBaseQuery[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = (values: any) => {
    let report: SaveReport = {
      historyEvents: [],
      name: values.title,
      resultDefinitions: [],
      versionNumber: 0,
      code: pascalcase(values.title),
      baseQueryCode: values.baseQuery.value,
      groupCode:
        (values.groupCode.value && values.groupCode.value) ||
        reportGroupss[reportGroupss.length - 1].code,
      filterDefinitions: [],
    };
    ReportConfigClient.saveReport(report).subscribe((result) => {
      ReportsActions.actionLoadReportGroups()(dispatch);
      setOpen(false);
    });
    setOpen(false);
    setBusyGroupSub(null);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 20);
  };
  useEffect(() => {
    ReportConfigClient.getAllBaseQueries().subscribe((x) => setBaseQueries(x));
  }, []);
  const reportGroupss = useSelector(
    (state: AppState) => state.reports.reportGroupList
  );
  return (
    <div>
      {group ? (
        <div style={{ marginRight: '12px' }}>
          <Button
            variant='outlined'
            style={{ borderColor: 'transparent', color: '#69b4de' }}
            onClick={() => setOpen(true)}>
            {t('NEW_REPORT', 'lag ny rapport')}
          </Button>
        </div>
      ) : (
        <div style={{ marginRight: '12px' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpen(true)}
            startIcon={<AddIcon />}>
            {t('NEW_REPORT', 'NY rapport')}
          </Button>
        </div>
      )}

      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={isOpen}
        onClose={() => setOpen(false)}>
        <DialogTitle>Lag ny rapport</DialogTitle>

        <Form
          id='report-form'
          onSubmit={onSubmit}
          render={({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div style={{ margin: '8px 0' }}>
                  <Field
                    width='100%'
                    name={'title'}
                    component={CubitTextFieldAdapter}
                    label={t('TITLE', 'Tittel')}
                    validate={(value) =>
                      ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                    }
                    variant='standard'
                  />
                </div>
                {group ? (
                  <div style={{ margin: '8px 0' }}>
                    <Field
                      width='100%'
                      name={'groupCode'}
                      component={CubitSelectAdapter}
                      label={t('REPORT_GROUP', 'Rapportgruppe')}
                      validate={(value) =>
                        ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                      }
                      variant='standard'
                      defaultValue={reportGroupss[reportGroupss.length - 1]}
                      options={reportGroupss.map((g) => ({
                        label: g.label,
                        value: g.code,
                      }))}
                      disabled
                    />
                  </div>
                ) : (
                  <div style={{ margin: '8px 0' }}>
                    <Field
                      width='100%'
                      name={'groupCode'}
                      component={CubitSelectAdapter}
                      label={t('REPORT_GROUP', 'Rapportgruppe')}
                      validate={(value) =>
                        ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                      }
                      variant='standard'
                      options={reportGroups.map((g) => ({
                        label: g.label,
                        value: g.code,
                      }))}
                    />
                  </div>
                )}

                <div style={{ margin: '8px 0' }}>
                  <Field
                    width='100%'
                    name={'baseQuery'}
                    component={CubitSelectAdapter}
                    label={t('BASE_QUERY', 'Base query')}
                    validate={(value) =>
                      ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                    }
                    variant='standard'
                    options={baseQueries.map((g) => ({
                      label: g.name,
                      value: g.code,
                    }))}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button variant={'text'} onClick={() => setOpen(false)}>
                  {t('CANCEL', 'Avbryt')}
                </Button>
                <Button variant={'contained'} type='submit'>
                  {t('saveReport', 'Lagre rapport')}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
};
