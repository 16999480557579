import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import { CubitTextFieldAdapter } from '../cubit-inputs/react-form-adapters/cubit-text-field-adapter';
import { useState } from 'react';
import { CubitSelectAdapter } from '../report-select-adapter';
import { useTranslation } from 'react-i18next';
import { ValidatorRequired } from '../../utils/validators/form-validators';
import { useSelector } from 'react-redux';
import { SaveReport } from '../../models/save-report';
import { AppState } from '../../redux/app-store';
import EditIcon from '@mui/icons-material/Edit';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    margin: '8px 0',
  },
  editIconContainer: {
    marginLeft: '12px',
  },
}));
export const EditReport = ({
  baseQuery,
  report,
  setSelectedReport,
  setSelectedGroup,
  setHasUnsavedChanges,
  group,
}) => {
  const styles = useStyles();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const reportGroups = useSelector(
    (state: AppState) => state.reports.reportGroupList
  );

  const onSubmit = (values: any) => {
    setHasUnsavedChanges(true);
    const newReport: SaveReport = {
      ...report,
      name: values.title,
      groupCode: values.groupCode.value || group.code,
    };
    setSelectedGroup(
      reportGroups.find((group) => group.code === newReport.groupCode)
    );
    setSelectedReport(newReport);
    setOpen(false);
  };
  return (
    <div>
      <div className={styles.editIconContainer}>
        <IconButton onClick={() => setOpen(true)}>
          <EditIcon sx={{ color: '#fff' }} />
        </IconButton>
      </div>

      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={isOpen}
        onClose={() => setOpen(false)}>
        <DialogTitle>
          {t('CHANGE_REPORT_OR_GROUP', 'Endre rapport og gruppe')}
        </DialogTitle>

        <Form
          id='report-edit-form'
          onSubmit={onSubmit}
          render={({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <div className={styles.fieldContainer}>
                  <Field
                    name={'title'}
                    component={CubitTextFieldAdapter}
                    label={t('TITLE', 'Tittel')}
                    validate={(value) =>
                      ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                    }
                    initialValue={report && report.name}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <Field
                    width='100%'
                    name={'groupCode'}
                    component={CubitSelectAdapter}
                    label={t('REPORT_GROUP', 'Rapportgruppe')}
                    validate={(value) =>
                      ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                    }
                    variant='standard'
                    defaultValue={reportGroups.find(
                      (reportGroup) => group.code === reportGroup.code
                    )}
                    options={reportGroups.map((g) => ({
                      label: g.label,
                      value: g.code,
                    }))}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <Field
                    name={'baseQuery'}
                    disabled
                    component={CubitTextFieldAdapter}
                    label={t('BASE_QUERY')}
                    validate={(value) =>
                      ValidatorRequired(value, t('TEXT_REQUIRED', 'Required'))
                    }
                    initialValue={baseQuery.name}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button variant={'text'} onClick={() => setOpen(false)}>
                  {t('CANCEL', 'Avbryt')}
                </Button>
                <Button variant={'contained'} type='submit'>
                  {t('saveReport', 'Lagre rapport')}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
};
